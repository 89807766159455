import Vue from 'vue';

export default Vue.directive('focus-cursor-end', {
  bind(el: HTMLElement, binding: any, vnode: any) {
    // this.event = event => {
    //   setTimeout(() => {
    //     const searchInput = event.target;
    //     const strLength = searchInput.value.length;
    //
    //     searchInput.setSelectionRange(strLength, strLength);v
    //   }, 5);
    // };
    //
    // el.addEventListener('focus', this.event);
  },

  unbind(el: HTMLElement, binding: any, vnode: any, oldVnode: any) {
    // el.removeEventListener('focus', this.event);
  },
});
